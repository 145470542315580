




































































































































































import ViewModel from '@/views/company/Recruit.ts';
export default ViewModel;
